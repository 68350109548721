<template>
  <div
    v-for="tab in tabs"
    :key="tab.key || keyFn?.(tab)"
    class="flex w-full flex-col"
  >
    <NuxtLink
      v-if="tab.to"
      v-slot="{ isActive }"
      :to="tab.to"
      :aria-label="tab.title"
    >
      <VerticalTab
        :="{ tab, isActive: checkActive(tab, isActive), unresponsive }"
      />
    </NuxtLink>
    <VerticalTab
      v-else
      :="{
        is: 'button',
        tab,
        isActive: checkActive(tab),
        unresponsive
      }"
    />
  </div>
</template>

<script setup lang="ts" generic="T extends Primitive">
// defines
defineOptions({ inheritAttrs: false })
const { keyFn, activeTab } = defineProps<{
  tabs: NavItem[]
  keyFn?: KeyFn<NavItem>
  activeTab?: T
  unresponsive?: boolean
}>()

// methods
const checkActive = (tab: NavItem, navActive?: boolean) => {
  if (!activeTab || (!tab.key && !keyFn)) return !!navActive
  return (tab.key || keyFn?.(tab)) === activeTab
}
</script>
